import React from "react";
import "../css/age.css";
import { Link } from "react-router-dom";

function Age() {
  const setAgeStatus = () => {
    localStorage.setItem("ageStatus", "true");
  };

  const goToNo = () => {
    localStorage.setItem("ageStatus", "false");
    window.open("https://google.com", "_blank");
  };

  return (
    <div className="App">
      <div className="age_section">
        <div className="age_container">
          <h1 className="h1_age">
            ¿Eres mayor <br></br>de 21 años?
          </h1>
          <div className="age_btn_container margin-right">
            <Link
              className="btn_div w-button"
              to="/home"
              onClick={() => setAgeStatus()}
            >
              SI
            </Link>

            <Link
              className="btn_div w-button margin-left"
              to="#"
              onClick={() => goToNo()}
            >
              NO
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Age;
