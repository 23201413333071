import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "../css/age.css";
import enviarBtn from "../img/enviar_btn_2.svg";
import emailjs from "emailjs-com";

function Home() {
  let history = useHistory();
  type ContactForm = {
    name: string;
    email: string;
    phone: string;
    msg: string;
  };

  const { register, handleSubmit, errors, reset } = useForm<ContactForm>();

  useEffect(() => {
    const status = localStorage.getItem("ageStatus");
    if (status === "false") history.push("/");
  }, [history]);

  const onSubmit = (data: ContactForm, e: any) => {
    const templateId = "template_5yx5kql";

    sendFeedback(templateId, {
      message_html: data.msg,
      from_name: data.name,
      reply_to: data.email,
      phone: data.phone,
    });
  };

  const sendFeedback = (templateId: string, variables: any) => {
    emailjs
      .send(
        "service_tyqeaa9",
        templateId,
        variables,
        "user_ydlzSEPAN1eEFU3gIsQ8j"
      )
      .then(
        (result) => {
          reset();
        },
        (error) => {
          console.log("error", error.text);
        }
      );
  };

  return (
    <div className="bg_home">
      <div className="w-container">
        <div className="logo"></div>
        <div className="h1_title">
          <h4 className="heading">the right choice for better health</h4>
          <div className="form_container">
            <div className="form_bg">
              <div className="mensaje_green_box">
                <div className="mensaje_title">deja un mensaje</div>
              </div>
              <div className="form_white_bg">
                <div className="form_block w-form">
                  <form className="form">
                    <div className="form_padding">
                      <input
                        type="text"
                        className={`text-field w-input ${
                          errors.name && errors.name.type === "required"
                            ? "error"
                            : ""
                        }`}
                        max="256"
                        name="name"
                        placeholder="Nombre"
                        required
                        ref={register({ required: true })}
                      ></input>

                      <input
                        type="email"
                        className={`text-field w-input ${
                          errors.email && errors.email.type === "required"
                            ? "error"
                            : ""
                        }`}
                        max="256"
                        name="email"
                        placeholder="E-mail"
                        required
                        ref={register({ required: true })}
                      ></input>

                      <input
                        type="tel"
                        className={`text-field w-input ${
                          errors.phone && errors.phone.type === "required"
                            ? "error"
                            : ""
                        }`}
                        max="256"
                        name="phone"
                        placeholder="Teléfono"
                        required
                        ref={register({ required: true })}
                      ></input>
                      <textarea
                        placeholder="Mensaje"
                        rows={5}
                        name="msg"
                        className="w-input"
                        ref={register}
                      ></textarea>
                    </div>
                    <div className="enviat_btn_container">
                      <img
                        className="pointer"
                        src={enviarBtn}
                        onClick={handleSubmit(onSubmit)}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="registrate_title">
          <div className="registrate_title">
            Regístrate para más información.
          </div>
        </div>
        <div className="new_website_text">
          <div className="new_website_label">A new Website COMING SOON!</div>
        </div>
      </div>
    </div>
  );
}

export default Home;
